<template>
  <div>
    <div class="companies">
      <ul class="logo-group">
        <li class="logo-item" :key="index" v-for="(brigad_view, index) in ds_brigad_view">
          <div v-if="brigad_view.torol_gishuun">
            <img class="STARS1" src="../../assets/pic/star.svg" alt="">
          </div>
          <div v-else>
            <img class="STARS1" src="../../assets/pic/star.svg" alt="" v-if="brigad_view.torol_gishuun_bus === 'Алтан багц'">
          </div>
          <div v-if="brigad_view.torol_gishuun">
            <img class="logo" src="../../assets/company_logo/1_gold.svg" alt="" v-if="brigad_view.torol_gishuun === 'Алтан багц'">
            <img class="logo" src="../../assets/company_logo/1_silver.svg" alt="" v-if="brigad_view.torol_gishuun === 'Мөнгөн багц'">
            <img class="logo" src="../../assets/company_logo/1_bronze.svg" alt="" v-if="brigad_view.torol_gishuun === 'Хүрэл багц'">
          </div>
          <div v-else>
            <img class="logo" src="../../assets/company_logo/2_gold.svg" alt="" v-if="brigad_view.torol_gishuun_bus === 'Алтан багц'">
            <img class="logo" src="../../assets/company_logo/2_silver.svg" alt="" v-if="brigad_view.torol_gishuun_bus === 'Мөнгөн багц'">
            <img class="logo" src="../../assets/company_logo/2_bronze.svg" alt="" v-if="brigad_view.torol_gishuun_bus === 'Хүрэл багц'">
          </div>
          <router-link :to="`/brgd_tanilts/${brigad_view.id}`">
            <img :src="IMAGE_URL + brigad_view.logo" alt="" v-if="brigad_view.logo != ''">
            <img src="../../assets/non.jpeg" alt="" v-else>
            <span class="span" v-if="language.short == 'mn'">{{ brigad_view.ner }}</span>
            <span class="span" v-else>{{ brigad_view.ner_en }}</span>
          </router-link>
          <brigad_follow_btn :company_id="brigad_view.id" type="brigad"></brigad_follow_btn>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import {GET_BRIGAD_VIEW_LIMIT_15, IMAGE_URL} from "../../graphql/queries";
import {mapGetters} from "vuex";
const brigad_follow_btn = () => import(/* webpackChunkName: "brigad_follow_btn" */ './brigad_follow_btn')
export default {
  name: "brigad",
  data () {
    return {
      loading: true,
      IMAGE_URL: IMAGE_URL,
      ds_brigad_view: []
    }
  },
  components: {
    "brigad_follow_btn": brigad_follow_btn
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ])
  },
  mounted() {
    this.getBrigad();
  },
  methods: {
    getDate(datetime) {
      let date = new Date(datetime).toJSON().slice(0, 10).replace(/-/g, '.')
      return date
    },
    getBrigad() {
      this.loading = true;
      this.$apollo.query({query: GET_BRIGAD_VIEW_LIMIT_15}).then(({data}) => {
        this.ds_brigad_view = data.ds_brilgas_view;
        this.loading = false;
      })
    },
  },
}
</script>